
.path {
	stroke-dasharray: 1000;
	stroke-dashoffset: 0;
}
.path.circle {
	-webkit-animation: dash 0.9s ease-in-out;
	animation: dash 0.9s ease-in-out;
}
.path.line {
	stroke-dashoffset: 1000;
	-webkit-animation: dash 0.9s 0.35s ease-in-out forwards;
	animation: dash 0.9s 0.35s ease-in-out forwards;
}
.path.check {
	stroke-dashoffset: -200;
	-webkit-animation: dash-check 0.9s 0.35s ease-in-out forwards;
	animation: dash-check 0.9s 0.35s ease-in-out forwards;
}
@-webkit-keyframes dash {
	0% {
	  	stroke-dashoffset: 1000;
	}
	100% {
	  	stroke-dashoffset: 0;
	}
}
@keyframes dash {
	0% {
	  	stroke-dashoffset: 1000;
	}
	100% {
	  	stroke-dashoffset: 0;
	}
}
@-webkit-keyframes dash-check {
	0% {
	  	stroke-dashoffset: -75;
	}
	100% {
	  	stroke-dashoffset: 875;
	}
}
@keyframes dash-check {
	0% {
	  	stroke-dashoffset: -75;
	}
	100% {
	  	stroke-dashoffset: 875;
	}
}
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-animation: autofill 0s forwards;
    animation: autofill 0s forwards;
}

@keyframes autofill {
    100% {
        background: transparent;
        color: inherit;
    }
}

@-webkit-keyframes autofill {
    100% {
        background: transparent;
        color: inherit;
    }
}


