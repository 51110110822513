
.path {
	stroke-dasharray: 1000;
	stroke-dashoffset: 0;
}
.path.circle {
	-webkit-animation: dash 0.9s ease-in-out;
	animation: dash 0.9s ease-in-out;
}
.path.line {
	stroke-dashoffset: 1000;
	-webkit-animation: dash 0.9s 0.35s ease-in-out forwards;
	animation: dash 0.9s 0.35s ease-in-out forwards;
}
.path.check {
	stroke-dashoffset: -200;
	-webkit-animation: dash-check 0.9s 0.35s ease-in-out forwards;
	animation: dash-check 0.9s 0.35s ease-in-out forwards;
}
@-webkit-keyframes dash {
	0% {
	  	stroke-dashoffset: 1000;
	}
	100% {
	  	stroke-dashoffset: 0;
	}
}
@keyframes dash {
	0% {
	  	stroke-dashoffset: 1000;
	}
	100% {
	  	stroke-dashoffset: 0;
	}
}
@-webkit-keyframes dash-check {
	0% {
	  	stroke-dashoffset: -75;
	}
	100% {
	  	stroke-dashoffset: 875;
	}
}
@keyframes dash-check {
	0% {
	  	stroke-dashoffset: -75;
	}
	100% {
	  	stroke-dashoffset: 875;
	}
}